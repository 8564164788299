import defaultProfilePicture from "../assets/media/defaultProfilePicture.svg";

import defaultServiceIcon from "../assets/media/service/default.svg";
import serviceHomeStyleCuisine from "../assets/media/service/HomeStyleCuisine.svg";
import serviceDishWashing from "../assets/media/service/DishWashing.svg";
import serviceBroomingMopping from "../assets/media/service/BroomingMopping.svg";
import serviceDusting from "../assets/media/service/Dusting.svg";
import serviceBathroomCleaningNormal from "../assets/media/service/BathroomCleaningNormal.svg";
import serviceCooksChefs from "../assets/media/service/Cooking.svg";
import serviceLaundryWithMachine from "../assets/media/service/LaundryWithMachine.svg";
import serviceIroning from "../assets/media/service/DryingCloths.svg";
import serviceDryingClothes from "../assets/media/service/DryingCloths.svg";
import serviceHangingCloths from "../assets/media/service/HangingCloths.svg";
import serviceFoldingCloths from "../assets/media/service/FoldingCloths.svg";
import serviceJapaMaids from "../assets/media/service/JapaMaids.svg";
import serviceNormalMaids from "../assets/media/service/NormalMaids.svg";
import serviceLiveInMaid from "../assets/media/service/NormalMaids.svg";
import serviceBathroomCleaningDeep from "../assets/media/service/BathroomCleaningDeep.svg";

import address from "../assets/media/address.svg";
import back from "../assets/media/back.svg";
import bookService from "../assets/media/book-service.svg";
import download from "../assets/media/download.svg";
import edit from "../assets/media/edit.svg";
import tick from "../assets/media/tick.svg";
import twistArrow from "../assets/media/twist-arrow.svg";
import whatsapp from "../assets/media/whatsapp.svg";

import addressIcon from "../assets/media/address-icon.svg";
import clockIcon from "../assets/media/clock.svg";
import paymentIcon from "../assets/media/payment.svg";
import successTickIcon from "../assets/media/success-tick.svg";
import noAddress from "../assets/media/no-address.svg"

import rightArrow from "../assets/media/right-arrow.svg";
import profileBooking from "../assets/media/profile-booking.svg";
import profileCardBg from "../assets/media/profile-card-bg.svg";
import title_img from "../assets/media/title_img.svg";

//TODO We need below in our assets dir
const bookingSuccess = "https://i.gifer.com/7efs.gif";
const bookingFailure = "https://pic.onlinewebfonts.com/svg/img_172745.png";

export {
  defaultProfilePicture,
  bookingSuccess,
  bookingFailure,
  defaultServiceIcon,
  serviceHomeStyleCuisine,
  serviceDishWashing,
  serviceBroomingMopping,
  serviceDusting,
  serviceBathroomCleaningNormal,
  serviceCooksChefs,
  serviceLaundryWithMachine,
  serviceIroning,
  serviceDryingClothes,
  serviceHangingCloths,
  serviceFoldingCloths,
  serviceJapaMaids,
  serviceNormalMaids,
  serviceLiveInMaid,
  serviceBathroomCleaningDeep,
  address,
  back,
  bookService,
  download,
  edit,
  tick,
  twistArrow,
  whatsapp,
  addressIcon,
  clockIcon,
  paymentIcon,
  successTickIcon,
  noAddress,
  rightArrow,
  profileBooking,
  profileCardBg,
  title_img
};
