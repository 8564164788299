import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import { MenuItem, Select, InputLabel } from "@mui/material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import { supabase } from "../../config/supabase";

// Import MUI theme
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Define a custom theme with a different primary color
const theme = createTheme({
  palette: {
    primary: {
      main: "#e41211",
    },
  },
});

// Define some styles for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "100%",
    sm: "60%",
    md: "35%",
  },
  height: {
    xs: "100%",
    sm: "80%",
    md: "80%",
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  borderColor: "white",
};

// Define some options for the form
const maidTypes = ["Part Time", "Full Time (8 or 10 hours)", "24x7 Live-in"];
const serviceTypes = [
  "Dusting",
  "Laundary",
  "Baby Care",
  "Utensil Cleaning",
  "Brooming/Mopping",
  "Cooking(Veg/Non Veg)",
  "Other",
];

// Define a custom component for the modal with the form
function BookServiceForm(props) {
  // Use state hooks to store the input values and errors
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [fullname, setFullname] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [maidType, setMaidType] = useState("");
  const [maidTypeError, setMaidTypeError] = useState("");
  const [serviceType, setServiceType] = useState({});
  const [serviceTypeError, setServiceTypeError] = useState("");
  const [otherServiceType, setOtherServiceType] = useState("");
  const [otherServiceTypeError, setOtherServiceTypeError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false); // Add state for T&C option
  const [termsAndConditionError, setTermsAndConditionsError] = useState("");

  // Handle the change of the input values
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "fullname":
        setFullname(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "city":
        setCity(value);
        break;
      case "maidType":
        setMaidType(value);
        break;
      case "serviceType":
        setServiceType((prev) => ({
          ...prev,
          [value]: !prev[value],
        }));
        break;
      case "otherServiceType":
        setOtherServiceType(value);
        break;
      case "termsAndConditions": // Handle T&C option change
        setTermsAndConditions(!termsAndConditions);
        break;
      default:
        break;
    }
  };

  // Validate the input values
  const validate = () => {
    let isValid = true;

    // Validate fullname
    if (!fullname) {
      isValid = false;
      setFullnameError("Fullname is required");
    } else {
      setFullnameError("");
    }

    // Validate phone
    if (!phone) {
      isValid = false;
      setPhoneError("Phone number is required");
    } else if (!/^\d{10}$/.test(phone)) {
      isValid = false;
      setPhoneError("Phone number must be exactly 10 digits");
    } else {
      setPhoneError("");
    }

    // Validate address
    if (!address) {
      isValid = false;
      setAddressError("Address is required");
    } else {
      setAddressError("");
    }

    // Validate city
    if (!city) {
      isValid = false;
      setCityError("City is required");
    } else {
      setCityError("");
    }

    // Validate maidType
    if (!maidType) {
      isValid = false;
      setMaidTypeError("Maid type is required");
    } else {
      setMaidTypeError("");
    }

    // Validate serviceType
    if (!Object.values(serviceType).some((v) => v)) {
      isValid = false;
      setServiceTypeError("At least one service type is required");
    } else {
      setServiceTypeError("");
    }

    // Validate otherServiceType
    if (serviceType.Other && !otherServiceType) {
      isValid = false;
      setOtherServiceTypeError("Other service type is required");
    } else {
      setOtherServiceTypeError("");
    }

    // Validate T&C option
    if (!termsAndConditions) {
      isValid = false;
      // Set error message for T&C option
      // Replace "Error message for T&C option" with your desired error message
      setTermsAndConditionsError("Please accept our Terms and Conditions.");
    } else {
      setTermsAndConditionsError("");
    }

    return isValid;
  };

  // Handle the submit of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedServiceTypes = Object.keys(serviceType).filter(
      (key) => serviceType[key]
    );
    if (validate()) {
      setIsSubmitting(true);
      // Insert the form data into the 'Form Data' table in Supabase
      const { data, error } = await supabase.from("Form Data").insert({
        fullname,
        phone,
        address,
        city,
        maidType,
        serviceType: selectedServiceTypes,
        otherServiceType,
      });
      if (error) {
        console.log(error);
        setError(true);
        setIsSubmitting(false);
      } else {
        console.log(data);
        setIsSubmitting(false);
        setOpen(true);
        setFullname("");
        setFullnameError("");
        setPhone("");
        setPhoneError("");
        setAddress("");
        setAddressError("");
        setCity("");
        setCityError("");
        setMaidType("");
        setMaidTypeError("");
        setServiceType({});
        setServiceTypeError("");
        setOtherServiceType("");
        setOtherServiceTypeError("");
        setTermsAndConditions("");
        setTermsAndConditionsError("");
      }
      // Close the modal after submitting
      props.onClose();
    }
  };

  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setTimeout(
        () => {
          handleClose();
        },
        error ? 4500 : 4500
      );
    }
  }, [open, error]);

  return (
    <ThemeProvider theme={theme}>
      <Modal open={props.open} onClose={props.onClose}>
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: "2rem", mt: "1.5rem" }}
          >
            Welcome to The Helpmate service booking form! Please add in all the
            information to make this a fruitful and a smooth collaboration
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="fullname"
                  label="Name"
                  value={fullname}
                  onChange={handleChange}
                  error={fullnameError ? true : false}
                  helperText={fullnameError}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phone"
                  label="Phone No"
                  value={phone}
                  onChange={handleChange}
                  error={phoneError ? true : false}
                  helperText={phoneError}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address"
                  label="Address"
                  value={address}
                  onChange={handleChange}
                  error={addressError ? true : false}
                  helperText={addressError}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel id="city-label">City</InputLabel>
                  <Select
                    labelId="city-label"
                    id="city-label-selector"
                    name="city"
                    label="city"
                    value={city}
                    onChange={handleChange}
                    error={cityError ? true : false}
                    required
                  >
                    <MenuItem value="">Select a city</MenuItem>
                    <MenuItem value="Delhi">Delhi</MenuItem>
                    <MenuItem value="Noida">Noida</MenuItem>
                    <MenuItem value="Noida Extension/Greater Noida west">
                      Noida Extension/Greater Noida west
                    </MenuItem>
                    <MenuItem value="Ghaziabad">Ghaziabad</MenuItem>
                    <MenuItem value="Greater Noida">Greater Noida</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {cityError && (
                    <FormHelperText error>{cityError}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  required
                  error={maidTypeError ? true : false}
                >
                  <FormLabel component="legend">
                    What type of maid are you looking for?
                  </FormLabel>
                  <RadioGroup
                    name="maidType"
                    value={maidType}
                    onChange={handleChange}
                    row
                  >
                    {maidTypes.map((type) => (
                      <FormControlLabel
                        key={type}
                        value={type}
                        control={<Radio />}
                        label={type}
                      />
                    ))}
                  </RadioGroup>
                  {maidTypeError && (
                    <Typography variant="caption" color="error">
                      {maidTypeError}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">
                    What service type are you looking for?
                  </FormLabel>
                  {serviceTypeError && (
                    <Typography variant="caption" color="error">
                      {serviceTypeError}
                    </Typography>
                  )}
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {serviceTypes.map((type) => (
                      <FormControlLabel
                        key={type}
                        control={
                          <Checkbox
                            name="serviceType"
                            value={type}
                            checked={serviceType[type] || false}
                            onChange={handleChange}
                          />
                        }
                        label={type}
                      />
                    ))}
                    {serviceType.Other && (
                      <TextField
                        name="otherServiceType"
                        label="What other service type do you need?"
                        value={otherServiceType}
                        onChange={handleChange}
                        error={otherServiceTypeError ? true : false}
                        helperText={otherServiceTypeError}
                        required
                      />
                    )}
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={termsAndConditions}
                        onChange={handleChange}
                        name="termsAndConditions"
                        color="primary"
                        required // added required prop
                      />
                    }
                    label={
                      <a
                        href="https://lvopkhrzmwbaimkixrna.supabase.co/storage/v1/object/public/hm-docs/Helpmate-%20Terms%20of%20Usage%20and%20Conditions.pdf?t=2023-03-31T11%3A34%3A47.193Z"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#e41211" }}
                      >
                        I agree to the terms and conditions
                      </a>
                    } // added style prop
                  />
                  {termsAndConditionError && ( // added error message
                    <Typography variant="caption" color="error">
                      {termsAndConditionError}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting} // added disabled prop
                  onClick={handleSubmit} // added onClick prop
                >
                  {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ textAlign: "center" }}>
          {error ? (
            <>
              <ErrorIcon
                sx={{ fontSize: "5rem", color: "#e41211", mb: "1rem" }}
              />
              <DialogContentText>
                There was a network error. Please reload the website and fill
                again.
              </DialogContentText>
            </>
          ) : (
            <>
              <CheckCircleIcon
                sx={{ fontSize: "5rem", color: "#e41211", mb: "1rem" }}
              />
              <DialogContentText>
                Form submited succesfully! Soon you will get a call back or
                whatsapp text from our executive.
              </DialogContentText>
            </>
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
export default BookServiceForm;
