import { useState, useEffect, useContext } from "react";
import { TextField, Button, InputAdornment } from "@mui/material";
import { RecaptchaVerifier } from "@firebase/auth";
import { auth, logInWithPhoneNumber } from "../../config/Firebase";
import { GlobalInfo } from '../../context/GlobalInfo';

export default function Login() {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [otpReceived, setOtpReceived] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const appVerifier = window.recaptchaVerifier;
    const { modalLogin } = useContext(GlobalInfo);
    const [loginModal, setLoginModal] = modalLogin;

    const recaptchaVerfication = () => {
        console.log("Verifying ReCaptcha");
        window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
                callback: (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // We should show OTP input box
                    setOtpReceived(true);
                },
                "expired-callback": () => {
                    // Response expired. Ask user to solve reCAPTCHA again.
                    // We should show check phone number and resend otp option
                },
            },
            auth
        );
    };

    useEffect(() => {
        recaptchaVerfication();
    }, []);

    const verifyOTP = async (code) => {
        try {
            console.log("Verifying OTP code");
            window.verifyingCode = true;
            let confirmationResult = window.confirmationResult;
            confirmationResult
                .confirm(code)
                .then(function (result) {
                    // User signed in successfully.
                    console.log("Code verified , user signed in successfully");
                    var user = result.user;
                    window.confirmationResult = null;
                    // console.log("user - ", user);
                    //   setUser(user);
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem('userPhone', user?.phoneNumber);
                    // window.location.reload();
                    setLoginModal(false)
                })
                .catch(function (error) {
                    // User couldn't sign in (bad verification code?)
                    console.error("Error while checking the verification code", error);
                });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="w-100 d-flex justify-content-center align-items-center flex-column">
            {
                otpReceived ?
                    <>
                        <TextField
                            label="Enter OTP"
                            type="number"
                            variant="outlined"
                            onChange={(e) => setOtp(e.target.value)}
                            value={otp}
                        />

                        {
                            isLoading ?
                                <button class="btn btn-primary" type="button"
                                    style={{ marginTop: '1em', background: '#e41211', color: '#fff', border: 'none', padding: '0.5em 1em', borderRadius: '5px' }}
                                >
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                    <span>&nbsp; {'Loading...'}</span>
                                </button>
                                :
                                <Button
                                    style={{ marginTop: '1em', background: '#e41211', color: '#fff', border: 'none', padding: '0.5em 1em', borderRadius: '5px' }}
                                    onClick={() => {
                                        setIsLoading(true);
                                        verifyOTP(otp);
                                        setIsLoading(false);
                                    }}>
                                    VERIFY OTP
                                </Button>
                        }
                    </>
                    :
                    <>
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span>+91</span>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        {
                            isLoading ?
                                <button class="btn btn-primary" type="button"
                                    style={{ marginTop: '1em', background: '#e41211', color: '#fff', border: 'none', padding: '0.5em 1em', borderRadius: '5px' }}
                                >
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                    <span>&nbsp; {'Loading...'}</span>
                                </button>
                                :
                                <button
                                    style={{ marginTop: '1em', background: '#e41211', color: '#fff', border: 'none', padding: '0.5em 1em', borderRadius: '5px' }}
                                    onClick={() => {
                                        setIsLoading(true);
                                        logInWithPhoneNumber(phoneNumber, appVerifier);
                                        setIsLoading(false);
                                    }}
                                >
                                    SEND OTP
                                </button>
                        }
                    </>
            }

            <div id="recaptcha-container"></div>
        </div>
    );
}
