import axios from "axios";
import { auth } from "../config/Firebase";

const controller = new AbortController();

const getRefreshedToken = async () => {
  try {
    let idToken = auth.currentUser.getIdToken();
    console.log("idToken - ", idToken);
    return idToken;
  } catch (error) {
    console.log("Error Refereshing Token - ", error);
    return null;
  }

  auth.onAuthStateChanged((user) => {
    if (user) {
      user.getIdToken().then((idToken) => {
        console.log(idToken);
        localStorage.setItem("refreshToken", idToken);
        return idToken;
      });
    }
  });
};

function ConnectionHelper() {
  async function getData({
    completeUrl,
    headers = { "Content-Type": "application/json" },
  }) {
    const token = await getRefreshedToken();

    return await axios({
      // signal: controller.signal,
      url: completeUrl,
      method: "get",
      headers: { ...headers, Authorization: token },
    })
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
  }

  async function postData({
    method = "post",
    completeUrl,
    headers = { "Content-Type": "application/json" },
    payload,
  }) {
    const token = await getRefreshedToken();

    return await axios({
      signal: controller.signal,
      url: completeUrl,
      method,
      headers: { ...headers, Authorization: token },
      data: payload,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async function putData({
    method = "put",
    completeUrl,
    headers = { "Content-Type": "application/json" },
    payload,
  }) {
    const token = await getRefreshedToken();

    return await axios({
      signal: controller.signal,
      url: completeUrl,
      method,
      headers: { ...headers, Authorization: token },
      data: payload,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  async function deleteData({
    method = "delete",
    completeUrl,
    headers = { "Content-Type": "application/json" },
    payload,
  }) {
    const token = await getRefreshedToken();

    return await axios({
      signal: controller.signal,
      url: completeUrl,
      method,
      headers: { ...headers, Authorization: token },
      data: payload,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  return {
    getData,
    postData,
    putData,
    deleteData,
  };
}

export default ConnectionHelper;
