import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import { Pagination, Autoplay } from "swiper";

const data = [
  {
    name: "Vanchha Garg",
    rating: 5,
    testimonial: "I am super happy with their services. If the maid cancels in the morning they provide replacement with in an hour and some times few minutes. You surely donot have to worry about the maid support if u have Helpmate. The maid hiring process is super easy. Meenakshi is extremely helpful and quick at her job. Every thing about them is easy and great. Highly recommended ❤️",
    date: "3 months ago",
  },
  {
    name: "Charu Jain",
    rating: 5,
    testimonial: "My experience is going really v.good with helpmate. Best thing about them is that their prices are v.genuine compared to other agencies who charge hefty amount from customers. Also, quality of maids are also good, they are experienced and properly trained beforehand. Last but not the least is the support that you can get from the support team. I have been in touch with meenakshi from helpmate and she is constantly available for any query resolution or any reply that i need. I really love to continue taking support from helpmate.",
      date: "a month ago",
  },
  {
    name: "Shipra Sood",
    rating: 5,
    testimonial: "We hired a full time maid from helpmate around a month back. Their process is quite easy and friendly. Meenakshi ji has been of great help with the whole process. She understands the requirement well and shares the profiles accordingly. Till now we have good experience with them.",
    date: "3 months ago",
  },
  {
    name: "Sankisha Pratham",
    rating: 5,
    testimonial: "They provided me very professional housemaid she was perfect in her work n adapted to our house environment with ease And my younger Son too adjusted with her very well usually he is Not frank to new face And that took of my tension of taking his care when I am at work.",
    date: "4 weeks ago",
  },
  {
    name: "Tanaya Banerjee",
    rating: 5,
    testimonial: "I like the fact that Helpmate is always there in one call. Thank you Meenakshi for being so proactive and helping whenever needed. Thank you Naina for your constant support. Helpmate is one of the best maid agency in Delhi NCR.. Keep doing a great work and help us by providing trained maids.",
    date: "3 months ago",
  },
  {
    name: "Prakash Kar",
    rating: 5,
    testimonial: "I am using their service since a month. I found them responsive, professional and effective in their commitment. I am using their domestic helper service. The helper they have provided, is really good in behavior wise and over all performance wise. Thanks to The Helpmate.",
    date: "3 months ago",
  },
  
  // ... and so on
];

export default function Testimonials() {
  return (
    <section id="testimonial">
      <section className="testimonial-section">
        <p className="test-title">Testimonials</p>
        <p className="test-subTitle">
          We specialise in making any area of cleaning less stressful. We will
          visit your location
          <br />
          and provide a free quotation so you are aware of the precise cost.
        </p>

        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1
            },
            768:{
              slidesPerView: 3
            }
          }}
          modules={[Pagination, Autoplay]}
          pagination={true}
          showsPagination={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {data?.map((val, index) => {
            return (
              <SwiperSlide className="testimonial-wrapper" key={index}>
                <div className="testimonial-box">
                  <Avatar sx={{ bgcolor: deepOrange[500] }}>{val.name.slice(0, 1)}</Avatar>
                  <p className="ratings">{"⭐".repeat(val.rating)}</p>
                  <p className="testimonial-text">{val.testimonial}</p>
                </div>
                <p className="ratingPerson">{val.name}</p>
                <span className="ratingDate">{val.date}</span>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    </section>
  );
}
