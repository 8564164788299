const packageReasons = [
  "All our listed helpers go through a fine selection process that builds trust on their services.",
  "Proper Identity Check for the home service workers.",
  "Document verification including their identity.",
  "Interview and conversation",
  "Training the workers for their work",
  "Send you a complete satisfaction package",
];

const packageFAQ = [
  {
    question: "What is HelpMate's promise?",
    answer:
      "HelpMate’s promise is to provide you with the best trained professionals who deliver amazing results and uphold a high standard of quality in their work.",
  },
  {
    question: "Do I need to be home during the services?",
    answer:
      "Ideally, it would be better for you to be home to overlook the service and make sure that everything is done to your satisfaction. It is also better for situations where you have to communicate with the domestic service professionals about your preferences and needs.",
  },
  {
    question: "Can I trust the maid?",
    answer:
      "Every maid associated with HelpMate is interviewed and verified through their government ids (Aadhaar, Ration Card, etc). Moreover, you can access a detailed profile of the maids on our webpage as well as mobile application.",
  },
];

export { packageReasons, packageFAQ };
