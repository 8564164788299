import React from "react";
// import twistedForward from '../../assets/media/twisted-forward.svg';

export default function HowItWorks() {
  return (
    <section id="howItWorks">
      <section className="work-section">
        <p className="ws-title">How it works?</p>
        <span className="ws-text">
        At our company, we're dedicated to providing you with stress-free home services.
        </span>
        <span className="ws-text">
        With just three simple steps, you can easily arrange for our professional maid service to take care of all your needs.
        </span>

        <div className="ws-steps">
          <div className="ws-step-wrapper">
            <div className="ws-step-box">
              <span className="ws-step-one">1</span>
            </div>
            <p className="ws-step-title">
              Book you service under{" "}
              <span style={{ color: "#E41211" }}>60 seconds</span>
            </p>
            <p className="ws-step-description">
              Get in touch with us quickly through our website
              <br />
              or call us directly to book a maid.
            </p>
          </div>
          {/* <img src={twistedForward} width="18%" /> */}
          <div className="ws-step-wrapper">
            <div className="ws-step-box">
              <span className="ws-step-one">2</span>
            </div>
            <p className="ws-step-title">
              Get the <span style={{ color: "#E41211" }}>best suited helper</span> for you
            </p>
            <p className="ws-step-description">
              We specialize in selecting the perfect maid for you
              <br />
              by providing profiles that are tailored to your needs.
            </p>
          </div>
          <div className="ws-step-wrapper">
            <div className="ws-step-box">
              <span className="ws-step-one">3</span>
            </div>
            <p className="ws-step-title">
              Schedule a <span style={{ color: "#E41211" }}>one day trial</span>
            </p>
            <p className="ws-step-description">
              Get a one day trial before hiring them because
              <br />
              its all about your perfect home.
            </p>
          </div>
        </div>
      </section>
    </section>
  );
}
