import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { twistArrow, bookService, title_img } from "../../constants/ImagePaths";
import packageTypes from "../../constants/PackageTypes";
import BookServiceForm from "./BookServiceForm";
import Fade from "@mui/material/Fade";
import { Typography } from "@mui/material";

export default function PackageTypes() {
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  // Handle the click of the button
  const handleClick = () => {
    setOpen(true);
  };

  // Handle the close of the modal
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setChecked(true);
  }, []);
  return (
    <section id="packageType">
      <section className="packageType-container">
        <div style={{ marginBottom: "20px" }}>
          <div className="pt-section-one">
            <img src={twistArrow} width="15%" className="twistArrow" />
            <div className="title-img-wrapper">
              <img src={title_img} width="80%" className="packageType-title" />
            </div>
          </div>
          <div className="service-btn">
            {/* <a href="https://forms.gle/AyGm3KjFsLGTpN1i6"> */}
            <button className="book-service-btn" onClick={handleClick}>
              <img
                src={bookService}
                style={{ marginRight: "10px", paddingBottom: "4px" }}
              />
              Book Your Services Now!
            </button>
            <BookServiceForm open={open} onClose={handleClose} />
          </div>
          <div className="separator" />
          <div className="pt-rating">
            <div>
              <Fade in={checked} timeout={2000}>
                <span className="pt-rating-text">5000+</span>
              </Fade>
              <br />
              <span style={{ margin: 0, padding: 0 }}>Customers</span>
            </div>
            <div className="rating">
              <Fade in={checked} timeout={2000}>
                <span className="pt-rating-text">80%+</span>
              </Fade>
              <br />
              <span style={{ margin: 0, padding: 0 }}>Retention</span>
            </div>
            <div className="rating">
              <Fade in={checked} timeout={2000}>
                <span className="pt-rating-text">4/5</span>
              </Fade>
              <br />
              <span>Average Rating</span>
            </div>
          </div>
        </div>

        <div>
          <div className="pt-section-two">
            {/* ALL_ROUNDERS */}
            <div className="pt-div pt-div-1">
              <div>
                <p className="pt-div-text">All Rounders</p>
                <div className="pt-div-description">
                  <div className="pt-div-line" />
                  <p className="pt-div-desctiption-text">
                    Choose a combination of cooking, baby care, odd
                    <br />
                    jobs, cleaning, etc!
                  </p>
                </div>
              </div>
            </div>

            {/* COOKS_CHEFS */}
            <div className="pt-div pt-div-2">
              <div>
                <p className="pt-div-text">Cooks/Chefs</p>
                <div className="pt-div-description">
                  <div className="pt-div-line" />
                  <p className="pt-div-desctiption-text">
                    Choose between basic home-
                    <br />
                    style, fancy or chef-style cooking
                    <br />
                    at affordable rates!
                  </p>
                </div>
              </div>
            </div>

            {/* HOUSE_HELPERS */}
            <div className="pt-div pt-div-3">
              <div>
                <p className="pt-div-text">House Helpers</p>
                <div className="pt-div-description">
                  <div className="pt-div-line" />
                  <p className="pt-div-desctiption-text">
                    Trained, punctual & verified
                    <br />
                    housekeepers for everyday
                    <br />
                    home cleaning!
                  </p>
                </div>
              </div>
            </div>

            {/* BABY_CARE */}
            <div className="pt-div pt-div-4">
              <div>
                <p className="pt-div-text">Baby Care</p>
                <div className="pt-div-description">
                  <div className="pt-div-line" />
                  <p className="pt-div-desctiption-text">
                    Experienced babysitters and
                    <br />
                    japa for all your child-care
                    <br />
                    needs!
                  </p>
                </div>
              </div>
            </div>

            {/* ADDITIONAL */}
            <div className="pt-div pt-div-5">
              <div>
                <p className="pt-div-text">Additional Services</p>
                <div className="pt-div-description">
                  <div className="pt-div-line" />
                  <p className="pt-div-desctiption-text">
                    Get your Bathrooms
                    <br />
                    Deep Cleaned!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
