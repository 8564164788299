import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SvgIcon from "@mui/material/SvgIcon";
import { Box, Container, Grid, Link, Typography } from "@mui/material";

// Define the footer component
function Footer() {
  // Define some links for the footer sections
  const ourService = [
    "All Rounders",
    "Cooks/Chefs",
    "Baby Care",
    "House Helpers",
    "Additional Service",
  ];
  const services = [
    "Maid Service in Noida",
    "Cook/chefs in Noida",
    "Baby Care in Noida",
    "House helpers in Noida",
    "Maid Service in Noida Extension/Greater Noida West",
    "Cook/chefs in Noida Extension/Greater Noida West",
    "Baby Care in Noida Extension/Greater Noida West",
    "House helpers in Noida Extension/Greater Noida West",
  ];

  const backgroundColor = "#000000";
  const headingColor = "#ffffff"; // Monochromatic shade of black
  const textColor = "#c0c0c0"; // Monochromatic shade of black
  const linkColor = "#808080"; // Monochromatic shade of black

  // Return the JSX code for rendering the footer
  return (
    <Box sx={{ bgcolor: backgroundColor }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} py={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" gutterBottom sx={{ color: headingColor }}>
              The Helpmate
            </Typography>
            <Typography variant="body1" sx={{ color: textColor }}>
              Helpmate’s promise is to provide you with the best trained maids
              who deliver amazing results and uphold a high standard of quality
              in their work.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom sx={{ color: headingColor }}>
              Our Services
            </Typography>
            <Grid container>
              <Grid item xs={6} md={6} sx={{mb: 1.5}}>
                {ourService.map((service) => (
                  <Link
                    href="#"
                    key={service}
                    variant="body2"
                    display="block"
                    sx={{ color: textColor, textDecoration: "none" }}
                    gutterBottom
                  >
                    {service}
                  </Link>
                ))}
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  {services.slice(5).map((item) => (
                    <Grid item xs={12}>
                      <Link
                        href="#"
                        key={item}
                        variant="body2"
                        display="block"
                        sx={{ color: textColor, textDecoration: "none" }}
                        gutterBottom
                      >
                        {item}
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{mt: '-3rem'}}>
                {services.slice(0, 5).map((item) => (
                  <Link
                    href="#"
                    key={item}
                    variant="body2"
                    display="block"
                    sx={{ color: textColor, textDecoration: "none" }}
                    gutterBottom
                  >
                    {item}
                  </Link>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography variant="h6" gutterBottom sx={{ color: headingColor }}>
              ContactUs
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              sx={{ marginBottom: ".75rem", color: textColor }}
              onClick={() => {
                if (window.innerWidth <= 768) {
                  window.location.href = "tel:9910779904";
                }
              }}
            >
              <SvgIcon fontSize="medium" sx={{ marginRight: "0.5rem" }}>
                <PhoneIcon />
              </SvgIcon>

              <Typography variant="body1">+91 9910779904</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ marginBottom: ".75rem", color: textColor }}
            >
              <SvgIcon fontSize="medium" sx={{ marginRight: "0.5rem" }}>
                <EmailIcon />
              </SvgIcon>
              <Typography variant="body1" sx={{ color: textColor }}>
                info@thehelpmate.in
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ width: "20rem", color: textColor }}
            >
              <SvgIcon fontSize="medium" sx={{ marginRight: "0.5rem" }}>
                <LocationOnIcon />
              </SvgIcon>
              <Typography variant="body1" sx={{ color: textColor }}>
                S.No 16 G/F, Yamnotri Complex Ambedkar Rd Ghaziabad, Uttar
                Pradesh, 201001
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ borderTop: 1, borderColor: { headingColor }, py: 2 }}></Box>
        <Typography align="center" sx={{ color: textColor }} fontSize="18px">
          © The Helpmate 2023. All rights reserved.
        </Typography>
        <Link
          href="https://lvopkhrzmwbaimkixrna.supabase.co/storage/v1/object/public/hm-docs/Helpmate-%20Terms%20of%20Usage%20and%20Conditions.pdf?t=2023-03-31T11%3A34%3A47.193Z"
          target="_blank"
          rel="noopener"
          sx={{
            color: linkColor,
            display: "flex",
            justifyContent: "center",
            textDecoration: "none",
          }}
        >
          Terms and Conditions
        </Link>
      </Container>
    </Box>
  );
}
export default Footer;
