import React from "react";
import { Card, CardContent, CardHeader, Typography,Grid } from "@mui/material";
import styled from "@emotion/styled";

const RedStrip = styled("div")({
  backgroundColor: "red",
  height: "50px",
  width: "10px",
  position: "absolute",
  top: "2rem",
  left: 0,
  transform: "translate(-50%, -25%) rotate(180deg)",
});

function Vision() {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{padding: '2rem', display: 'flex', justifyContent: 'center'}}>
      <Grid item xs={12} sm={6} md={4}>
      <Card
        elevation={3}
        sx={{
          maxWidth: 500,
          position: "relative",
          boxShadow: "0px 8px 40px 10px #0000000d",
          padding: "1rem",
          borderRadius: "10px",
        }}
      >
        <CardHeader
          title="Our Vision"
          titleTypographyProps={{ variant: "h4", fontWeight: 700 }}
        />
        <RedStrip />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
          At our company, we strive to make booking a professional, fully verified, and well-trained maid or domestic helper a breeze for every household. 
          Our top priority is to provide exceptional household services that meet your unique needs and preferences. 
          With just a click, you can easily access our platform and find the best domestic helpers and maids in your area.
          </Typography>
        </CardContent>
      </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
      <Card
        elevation={3}
        sx={{
          maxWidth: 500,
          position: "relative",
          boxShadow: "0px 8px 40px 10px #0000000d",
          padding: "1rem",
          borderRadius: "10px",
        }}
      >
        <CardHeader
          title="Our Mission"
          titleTypographyProps={{ variant: "h4", fontWeight: 700 }}
        />
        <RedStrip />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            We also aim to create sustainable livelihoods for our domestic helpers, ensure their well being, 
            and build long lasting relationships with both our helpers and customers for a mutually beneficial and fulfilling experience. 
            We are deciated in providing you all the domestic services from cleaning to cooking to dish washing, laundry/washing clothes and much more.
          </Typography>
        </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
}

export default Vision;
