import PackageTypes from "../components/home/PackageTypes.js";
import HowItWorks from "../components/home/HowItWorks.js";
import WhyChooseUs from "../components/home/WhyChooseUs.js";
import Testimonials from "../components/home/Testimonials.js";
import Faq from "../components/home/Faq.js";
import OtherOptions from "../components/home/OtherOptions.js";
import FeaturedSection from "../../src/components/about_us/FeaturedOn.js";

const Home = () => {
  return (
    <>
      <PackageTypes />
      <HowItWorks />
      <WhyChooseUs />
      <Testimonials />
      <Faq /> 
      <OtherOptions />
      <FeaturedSection />
    </>
  );
}
export default Home;