import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@mui/material";

import { GlobalInfo } from "../../context/GlobalInfo";
import Constants from "../../constants/Constants";
import packageTypes from "../../constants/PackageTypes";
import { packageReasons, packageFAQ } from "../../constants/PackageReasonsFaq";
import ServiceBaseLayout from "../../layouts/ServiceBaseLayout";

import {
  tick,
  defaultServiceIcon,
  serviceHomeStyleCuisine,
  serviceDishWashing,
  serviceBroomingMopping,
  serviceDusting,
  serviceBathroomCleaningNormal,
  serviceCooksChefs,
  serviceLaundryWithMachine,
  serviceIroning,
  serviceDryingClothes,
  serviceHangingCloths,
  serviceFoldingCloths,
  serviceJapaMaids,
  serviceNormalMaids,
  serviceLiveInMaid,
  serviceBathroomCleaningDeep,
} from "../../constants/ImagePaths";

export default function PackageDetails() {
  let navigate = useNavigate();
  let location = useLocation();
  let packageTyp = location?.state?.packageType;
  let title = Constants.TITLE_PACKAGE_DETAILS_ALL_ROUNDERS;
  let packagePrice = Constants.PRICE_PACKAGE_ALL_ROUNDERS;

  const { bookingRequestGlobal, masterBookingAttribute } = useContext(GlobalInfo);
  const [bookingRequest, setBookingRequest] = bookingRequestGlobal;
  const [serviceTypeArr, setServiceTypeArr] = useState({});

  var serviceIconArray = {
    "default": defaultServiceIcon,
    "Home Style Cuisine": serviceHomeStyleCuisine,
    "Dish Washing": serviceDishWashing,
    "Brooming/Mopping": serviceBroomingMopping,
    "Dusting": serviceDusting,
    "Bathroom Cleaning (Normal)": serviceBathroomCleaningNormal,
    "Cooks/Chefs": serviceCooksChefs,
    "Laundry With Machine": serviceLaundryWithMachine,
    "Ironing": serviceIroning,
    "Drying Clothes": serviceDryingClothes,
    "Hanging Cloths": serviceHangingCloths,
    "Folding Cloths": serviceFoldingCloths,
    "Japa Maids": serviceJapaMaids,
    "Normal Maids": serviceNormalMaids,
    "LiveIn Maid": serviceLiveInMaid,
    "Bathrrom Cleaning (Deep)": serviceBathroomCleaningDeep
  };

  switch (packageTyp) {
    case packageTypes.COOKS_CHEFS:
      title = Constants.TITLE_PACKAGE_DETAILS_COOKS_CHEFS;
      packagePrice = Constants.PRICE_PACKAGE_COOKS_CHEFS;
      break;
    case packageTypes.HOUSE_HELPERS:
      title = Constants.TITLE_PACKAGE_DETAILS_HOUSE_HELPERS;
      packagePrice = Constants.PRICE_PACKAGE_HOUSE_HELPERS;
      break;
    case packageTypes.BABY_CARE:
      title = Constants.TITLE_PACKAGE_DETAILS_BABY_CARE;
      packagePrice = Constants.PRICE_PACKAGE_BABY_CARE;
      break;
    case packageTypes.ADDITIONAL:
      title = Constants.TITLE_PACKAGE_DETAILS_ADDITIONAL;
      packagePrice = Constants.PRICE_PACKAGE_ADDITIONAL;
      break;
    default:
      title = Constants.TITLE_PACKAGE_DETAILS_ALL_ROUNDERS;
      packagePrice = Constants.PRICE_PACKAGE_ALL_ROUNDERS;
  }

  const handleRadioAndTextAreaBtn = (event) => {
    const { name, value } = event.target;
    setServiceTypeArr({ [name]: value });
    console.log("radio btn=> ", serviceTypeArr);
  };

  const handleServiceSelection = (event) => {
    const { name, checked } = event.target;
    setServiceTypeArr((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    setBookingRequest({ ...bookingRequest, packageType: packageTyp });
  }, []);

  return (
    <ServiceBaseLayout title={title}>
      <div>
        {/* Package Illustration */}
        {/* <div className="bannerWrapper" /> */}

        <div className="serviceList">
          <p className="service-title">Services we provide</p>
          {/* <div className="d-flex text-center"> */}
          <div className="text-center selection-box-container">

            <FormControl fullWidth>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {Object.keys(masterBookingAttribute[packageTyp])?.map((val, index) => {
                  return (
                    <div
                      key={index}
                      // className="px-2 py-2 d-flex align-items-center"
                      className="px-2 py-2"
                    >
                      <FormControlLabel
                        value={val}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              "&.Mui-checked": { color: "#E41211" },
                              visibility: 'hidden'
                            }}
                          />
                        }
                        name={val}
                        onChange={handleRadioAndTextAreaBtn}
                        label={
                          <div className="selection-opt-box" style={{ border: serviceTypeArr[val] === val ? '1px solid red' : null }}>
                            <img src={serviceIconArray[val] ? serviceIconArray[val] : serviceIconArray["default"]} width="80px" height="auto" style={{ marginRight: "5px" }} />
                            <span style={{ fontSize: '0.9em', padding: '3px 0 0 0', margin: '0' }}>{val}</span>
                          </div>
                        }
                      />
                    </div>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        {/* Reasons to Choose us */}
        <div className="reasons-wrapper">
          <p className="reasons-title">Reasons to choose us</p>
          {packageReasons?.map((val, index) => {
            return (
              <div className="d-flex align-items-start" key={index}>
                <img src={tick} className="pt-1" />
                <p className="px-1 reasons-text">{val}</p>
              </div>
            );
          })}
        </div>

        {/* FAQ */}
        <div className="faq-wrapper">
          <p className="reasons-title">Frequenty asked questions</p>
          {packageFAQ?.map((val, index) => {
            return (
              <div key={index}>
                <p className="mb-1 fw-bold reasons-text">Q: {val?.question}</p>
                <p className="reasons-text">A: {val?.answer}</p>
              </div>
            );
          })}
        </div>
        <div className="viewall-faq d-flex justify-content-center align-items-center">
          <p className="viewall-faq-text mt-2">View All Questions</p>
        </div>

        {/* Pricing and Book   */}
        <div className="d-flex justify-content-between align-items-center px-2 py-2 mt-5">
          <div>
            <p className="mb-0 book-price-text">Starting at</p>
            <p className="mb-0 book-price">
              <strong>{packagePrice}</strong>/day
            </p>
          </div>
          <button
            className="book-btn"
            onClick={() => {
              if (Object.keys(serviceTypeArr).length !== 0) {
                navigate("/package/service", {
                  state: {
                    packageType: packageTyp,
                    serviceTypes: Object.keys(serviceTypeArr),
                  },
                });
              } else {
                alert("Atleast one service needs to be selected.");
              }
            }}
          >
            Estimate & Book Service
          </button>
        </div>
      </div>
    </ServiceBaseLayout>
  );
}
