import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import faq from "../../constants/FAQ";

export default function Faq() {
  return (
    <section id="faq">
      <section className="faq-section">
        <p className="faq-title">Frequently Asked Questions</p>

        <div className="faq-list">
        {faq?.map((val, ind) => {
            return (
              <Accordion key={ind} sx={{ marginBottom: "20px" }}>
                <AccordionSummary
                  expandIcon={<span>+</span>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ textAlign: "left" }}>{val.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ textAlign: "left" }}>
                    {val.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </section>
    </section>
  );
}
