import React from "react";
import { Box, Grid, ImageList, ImageListItem } from "@mui/material";
import featuredSection from "../../assets/media/featuredSection.svg";

const FeaturedSection = () => {
      return (
        <Box sx={{ py: 4 }}>
          <span style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
            <img src={featuredSection} alt="Featured on" style={{ width: "50rem" }} />
          </span>
        </Box>
      );
}
export default FeaturedSection;