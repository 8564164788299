import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Tab,
  Tabs,
  Typography,
  Hidden,
  useTheme
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

import sarthakProfile from "../../assets/media/sarthak-profile.jpg";
import ujjwalProfile from "../../assets/media/ujjwal-profile.jpg";
import nagendraProfile from "../../assets/media/nagendra-profile.jpg";
import siddhantProfile from "../../assets/media/siddhant-profile.jpg";
import varunProfile from "../../assets/media/varun-profile.jpg";
import shailendraProfile from "../../assets/media/shailendra-profile.jpg";
import rohitProfile from "../../assets/media/rohit-profile.jpg";
import bffLogo from "../../assets/media/bff-logo.jpeg";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 140,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  justifyContent: "flex-end",
}));
const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
}));
function TabPanel(props) {
  // Get the props for the tab panel
  const { children, value, index } = props;

  // Return the JSX code for rendering the tab panel
  return (
    <Box role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </Box>
  );
}
const MeetTheTeam = () => {
  const theme = useTheme();
  const isMobile = theme.breakpoints.only("sm");
  const teamMembers = [
    {
      photoUrl: sarthakProfile,
      name: "Sarthak Mittal",
      designation: "Team",
      details: "CEO/Founder",
      linkedinUrl: "https://www.linkedin.com/in/sarthakmittal1/",
    },
    {
      photoUrl: ujjwalProfile,
      name: "Ujjwal Wason",
      designation: "Team",
      details: "Head of Operations",
      linkedinUrl: "https://www.linkedin.com/in/ujjwal-wason-417a81195/",
    },
    {
      photoUrl: nagendraProfile,
      name: "Nagendra Singh",
      designation: "Team",
      details: "Head of BD & Growth",
      linkedinUrl: "https://www.linkedin.com/in/nagendra-singh-aa823043/",
    },
    {
      photoUrl: siddhantProfile,
      name: "Siddhant Jain",
      designation: "Team",
      details: "SDE",
      linkedinUrl: "https://www.linkedin.com/in/sidd97/",
    },
    {
      photoUrl: varunProfile,
      name: "Varun Aggrawal",
      designation: "Investor & Mentor",
      details: "Founder @Change Engine | Founder @Aspiring Minds. ",
      linkedinUrl: "https://www.linkedin.com/in/varunaggarwal2/",
    },
    {
      photoUrl: shailendraProfile,
      name: "Shailendra Jha",
      designation: "Investor & Mentor",
      details: "Co-Founder @Change Engine | Ex-Vice President of product management @Spinny",
      linkedinUrl: "https://www.linkedin.com/in/shailendra-nath-jha-3969768/",
    },
    {
      photoUrl: rohitProfile,
      name: "Rohit Kapoor",
      designation: "Investor & Mentor",
      details: "CEO-Food Marketplace @Swiggy",
      linkedinUrl: "https://www.linkedin.com/in/rohit-kapoor-99a30436/",
    },
  ];
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabs = ["Team", "Investor & Mentor"];
  return (
    <Box sx={{ maxWidth: 960, margin: "0 auto", padding: "1rem" }}>
      <Typography variant={isMobile ? "h5" : "h1"} sx={{display: 'flex', justifyContent: 'center'}}>Faces Behind The HelpMate</Typography>
      <Tabs
        value={value}
        textColor="black"
        onChange={handleChange}
        aria-label="team members tabs"
        centered
        TabIndicatorProps={{ style: { background: "red" } }}
      >
        {tabs.map((tab) => (
          <Tab key={tab} label={tab} />
        ))}
      </Tabs>
      {tabs.map((tab) => (
        <TabPanel key={tab} value={value} index={tabs.indexOf(tab)}>
         {tab === "Investor & Mentor" && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <a href="https://bff.investopad.com/" target="_blank" rel="noopener noreferrer">
                <img src={bffLogo} alt="Bharat Founders Fund Logo" />
              </a>
            </Box>
          )}
          <Grid
            container
            spacing={2}
            sx={{ padding: "2rem" }}
            justifyContent="center"
          >  
            {teamMembers
              .filter((teamMember) => teamMember.designation === tab)
              .map((teamMember) => (
                <Grid item key={teamMember.name} xs={12} sm={6} md={4}>
                  <StyledCard>
                    <StyledCardHeader
                      avatar={
                        // Center the avatar on all screens and make it overflow at the top center of the card
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Avatar
                            src={teamMember.photoUrl}
                            alt={teamMember.name}
                            sx={{
                              width: { lg: "60px" },
                              height: { lg: "60px" },
                            }}
                          />
                        </Box>
                      }
                      action={
                        // Center the action on all screens
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <IconButton href={teamMember.linkedinUrl}>
                            <LinkedInIcon />
                          </IconButton>
                        </Box>
                      }
                      title={
                        // Stack the name and designation vertically on all screens
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: ".25rem",
                          }}
                        >
                          {teamMember.name}
                          {/* Show subheader on all screens */}
                          {/* Make subheader appear below name and add some spacing */}
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            component="span"
                            sx={{ display: "block" }}
                          >
                            {teamMember.designation}
                          </Typography>
                        </Box>
                      }
                      // Center the action and title on all screens
                      // Make card header take up full width of card on all screens
                      sx={{
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    />
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {teamMember.details}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      ))}
    </Box>
  );
};
export default MeetTheTeam;
