import getEnvVars from "../environment";

const { ENV_BASE_URL, ENV_BASE_URL_DOCUMENT, ENV_BASE_URL_PAYMENT, ENV_BASE_URL_PAYMENT_PG } = getEnvVars();

const Constants = {
  // -------------------------------------------------------------------------------//
  // URL CONSTANTS                                                                  //
  // -------------------------------------------------------------------------------//

  // Download Helper Picture
  URL_DOCUMENT_DOWNLOAD: ENV_BASE_URL_DOCUMENT + "/helper/download?fileKey=",

  // Master - Data
  URL_MASTER_LOCATION_GET: ENV_BASE_URL + "/master/location",
  URL_MASTER_LOCATION_HIERARCHICAL: ENV_BASE_URL + "/master/location-hierarchical",
  URL_MASTER_BOOKING_ATTRIBUTE_GET: ENV_BASE_URL + "/master/booking-attribute",
  URL_MASTER_BOOKING_ATTRIBUTE_HIERARCHICAL: ENV_BASE_URL + "/master/booking-attribute-hierarchical",
  URL_MASTER_BOOKING_LIFECYCLE_STATUS_GET: ENV_BASE_URL + "/master/booking-lifecycle-status",

  // Booking
  URL_BOOKING_CREATE: ENV_BASE_URL + "/booking",
  URL_BOOKING_GET: ENV_BASE_URL + "/booking",

  // BookingMAP
  URL_BOOKINGMAP_GET: ENV_BASE_URL + "/bookingmap",
  URL_BOOKINGMAP_GET_BY_BOOKING: ENV_BASE_URL + "/bookingmap/booking",
  URL_BOOKINGMAP_GET_BY_HELPER: ENV_BASE_URL + "/bookingmap/helper",

  // BookingBill
  URL_BOOKINGBILL_GET: ENV_BASE_URL + "/bookingbill",
  URL_BOOKINGBILL_GET_BY_BOOKING: ENV_BASE_URL + "/bookingbill/booking",
  
  // Subscription
  URL_SUBSCRIPTION_CREATE: ENV_BASE_URL + "/subscription",  
  URL_SUBSCRIPTION_GET: ENV_BASE_URL + "/subscription",

  // Payment
  URL_PAYMENT_CREATE: ENV_BASE_URL + "/bookingpayment",  
  URL_PAYMENT_UPDATE: ENV_BASE_URL + "/bookingpayment",
  URL_PAYMENT_GET: ENV_BASE_URL + "/bookingpayment",
  URL_PAYMENT_GET_BY_BILL: ENV_BASE_URL + "/bookingpayment/bookingbill",
  URL_PAYMENT_GET_BY_SUBSCRIPTION: ENV_BASE_URL + "/bookingpayment/subscription",

  // Payment Gateway (Node.js) Backend 
  URL_PGPAYMENT_INITIATE: ENV_BASE_URL_PAYMENT + "/payment",
  URL_PGPAYMENT_ORDER_PROCESS: ENV_BASE_URL_PAYMENT_PG + "/order/process",

  // Customer Location
  URL_CUSTOMER_LOCATION: ENV_BASE_URL + "/customer-location",

  // -------------------------------------------------------------------------------//
  // API Response Flags                                                             //
  // -------------------------------------------------------------------------------//
  RESPONSE_SUCCESS: "SUCCESS",
  RESPONSE_FAILURE: "FAILURE",
  PAYMENT_SUCCESS: "TXN_SUCCESS",
  PAYMENT_FAILURE: "TXN_FAILURE",

  // -------------------------------------------------------------------------------//
  // Page Title                                                                     //
  // -------------------------------------------------------------------------------//
  TITLE_PACKAGE_DETAILS_COOKS_CHEFS: "Cooks/Chefs",
  TITLE_PACKAGE_DETAILS_HOUSE_HELPERS: "House Helpers",
  TITLE_PACKAGE_DETAILS_BABY_CARE: "Baby Care",
  TITLE_PACKAGE_DETAILS_ADDITIONAL: "Additional",
  TITLE_PACKAGE_DETAILS_ALL_ROUNDERS: "All Rounders",

  TITLE_SERVICE_DETAILS: "Book Service",
  TITLE_SERVICE_BOOKING: "Select A Slot",
  TITLE_BOOKING_ADDRESS: "Add Address",
  TITLE_BOOKING_LIST: "My Bookings",
  TITLE_BOOKING_DETAILS: "Booking Details",
  TITLE_ADDRESS_LIST: "My Address",
  TITLE_PROFILE: "My Profile",
  TITLE_BILL_LIST: "My Bills",
  TITLE_BILLPAYMENT_LIST: "Payment History",
  TITLE_PAYMENT_LIST:"Transaction History",
  TITLE_SUBSCRIPTION_LIST: "My Subscriptions",
  TITLE_SUBSCRIPTION_DETAILS: "Subscription Details",

  // -------------------------------------------------------------------------------//
  // Package Starting Price
  // -------------------------------------------------------------------------------//
  PRICE_PACKAGE_COOKS_CHEFS: "₹156",
  PRICE_PACKAGE_HOUSE_HELPERS: "₹166",
  PRICE_PACKAGE_BABY_CARE: "₹176",
  PRICE_PACKAGE_ADDITIONAL: "₹186",
  PRICE_PACKAGE_ALL_ROUNDERS: "₹196",

  // -------------------------------------------------------------------------------//
  // Service Attribute Data Type
  // -------------------------------------------------------------------------------//
  SERVICE_ATTRIBUTE_DATA_TYPE_RADIO: "Radio",
  SERVICE_ATTRIBUTE_DATA_TYPE_CHECKBOX: "Checkbox",
  SERVICE_ATTRIBUTE_DATA_TYPE_COUNTER: "Counter",
  SERVICE_ATTRIBUTE_DATA_TYPE_TEXTAREA: "Textarea",

};

export default Constants;
