const ENV_VAR = {
  ENV_BASE_URL: "https://ksqe59et34.execute-api.ap-south-1.amazonaws.com/dev",
  ENV_BASE_URL_DOCUMENT: "https://155ivtykv0.execute-api.ap-south-1.amazonaws.com/dev",
  ENV_BASE_URL_PAYMENT: "https://8hqnhlxfz2.execute-api.ap-south-1.amazonaws.com/dev",
  ENV_BASE_URL_PAYMENT_PG: "https://securegw-stage.paytm.in",

  // FIREBASE CONFIG
  ENV_FIREBASE_API_KEY: "AIzaSyDNmd0OJwIMuB4geSlh4sTDu3AfLjNaQoM",
  ENV_FIREBASE_AUTH_DOMAIN: "nv-hm-dev.firebaseapp.com",
  ENV_FIREBASE_PROJECT_ID: "nv-hm-dev",
  ENV_FIREBASE_STORAGE_BUCKET: "nv-hm-dev.appspot.com",
  ENV_FIREBASE_MESSAGING_SENDERID: "943057141140",
  ENV_FIREBASE_APP_ID: "1:943057141140:web:3ca73fb71570f56490a381",
  ENV_FIREBASE_MEASUREMENT_ID: "G-7X1H7M629N",
};

const getEnvVars = () => {
  return ENV_VAR;
};

export default getEnvVars;