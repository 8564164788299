import React,{ useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import chefCooking from "../../assets/media/chef-cooking.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BookServiceForm from "./BookServiceForm";
import { bookService } from "../../constants/ImagePaths";

function BlogHeader() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  // Handle the close of the modal
  const handleClose = () => {
    setOpen(false);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#e41211",
      },
    },
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      sx={{
        height: isSmallScreen ? 200 : 300,
        backgroundColor: "#f0f0f0",
        position: "relative",
      }}
    >
      <CardMedia
        component="img"
        image={chefCooking}
        alt="chef-cooking"
        title="How to Book a Cook on Helpmate?"
        sx={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <Box
        p={3}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: isSmallScreen ? "100%" : "50%",
          padding: "2rem",
        }}
      >
        <Typography sx={{ fontSize: isSmallScreen ? "1.2rem" : "2rem" }}>
          How to book maid service on The Helpmate?
        </Typography>
        <Typography
          sx={{
            fontSize: isSmallScreen ? ".9rem" : "1rem",
            marginTop: "20px",
          }}
        >
          Create a booking by clicking on the button{" "}
          <button className="book-service-btn" onClick={handleClick}>
            <img
              src={bookService}
              style={{ marginRight: "10px", paddingBottom: "4px" }}
            />
            Book Your Services Now!
          </button>{" "}<br/>
          now or call us directly at 9910779904.
        </Typography>
        <BookServiceForm open={open} onClose={handleClose} />
        <ThemeProvider theme={theme}></ThemeProvider>
      </Box>
    </Grid>
  );
}

export default BlogHeader;
