import React, { createContext, useState, useEffect } from "react";
import ConnectionHelper from "../connections/ConnectionHelper";
import Constants from "../constants/Constants";

export const GlobalInfo = createContext();

const { getData } = ConnectionHelper();

function GlobalInfoProvider({ children }) {
  const [masterLocation, setMasterLocation] = useState({});
  const [masterBookingAttribute, setMasterBookingAttribute] = useState({});
  const [bookingRequest, setBookingRequest] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: '', type: "success" });
  const [loginModal, setLoginModal] = useState(false);

  const getMasterLocation = async () => {
    const response = await getData({
      completeUrl: Constants.URL_MASTER_LOCATION_HIERARCHICAL,
    });
    let responseLocations = response?.responseHierarchicalLocation;
    setMasterLocation(responseLocations);
    console.log("responseLocations ", + JSON.stringify(responseLocations))
  };

  const getMasterBookingAttribute = async () => {
    const response = await getData({
      completeUrl: Constants.URL_MASTER_BOOKING_ATTRIBUTE_HIERARCHICAL,
    });
    let responseBookingAttributes =
      response?.responseHierarchicalBookingAttributes;
    setMasterBookingAttribute(responseBookingAttributes);
  };

  useEffect(() => {
    getMasterLocation();
    getMasterBookingAttribute();
  }, []);

  return (
    <GlobalInfo.Provider
      value={{
        masterLocation,
        masterBookingAttribute,
        bookingRequestGlobal: [bookingRequest, setBookingRequest],
        snack:[snackbar, setSnackbar],
        modalLogin:[loginModal, setLoginModal]
      }}
    >
      {children}
    </GlobalInfo.Provider>
  );
}

export default GlobalInfoProvider;
