import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import workers from "../../assets/media/workers.svg";
import dissatisfaction from "../../assets/media/dissatisfaction.svg";
import manage from "../../assets/media/manage.svg";
import support from "../../assets/media/support.svg";

const data = [
  {
    image: workers,
    title: "Trained and Verified helpers",
    description:
      "We offer trained helpers and get their background verfication. We take pride in ensuring that our helpers are trustworthy and capable, so you can have peace of mind knowing that your home and loved ones are in good hands.",
  },
  {
    image: dissatisfaction,
    title: "Immediate Replacement",
    description:
      "In addition to offering permanent replacements for helpers,we also provide one-day replacements when your regular helper takes a leave. This means that you don't have to worry about any disruptions to your daily routine or household chores.",
  },
  {
    image: manage,
    title: "Efficient Support System",
    description: "Our dedicated relationship managers are available to assist you at every stage and with any issues you may encounter. Our experienced relationship managers are there to provide you with the support and resources you need to ensure a smooth and hassle-free experience.",
  },
  {
    image: support,
    title: "Competitive Pricing",
    description: "Unlike many other agencies or startups, we believe in offering competitive market pricing that is fair and transparent. We strive to provide high-quality services at reasonable rates, and we never charge more than necessary.",
  },
];

const CardComponent = ({ image, title, description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("md")
  );

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        elevation: 4,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: isMobile ? "100%" : "22%",
          height: isMobile ? 200 : "auto",
          objectFit: "contain",
        }}
        image={image}
        alt={title}
      />
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{ fontWeight: "bolder", textAlign: 'center' }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" fontSize="1rem">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};
const WhyChooseUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("md")
  );
  return (
    <>
      <Typography variant="h4" align="center" sx={{ mt: 4,fontSize: isMobile? '2.5rem': '3rem', fontWeight: "bolder" }}>Why Choose Us?</Typography>
      <Grid
        container
        spacing={4}
        sx={{ p: isMobile ? "1.25rem" : "3rem 10rem 5rem 10rem" }}
        justifyContent="center"
        alignItems="center"
      >
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={6} xl={6} key={index}>
            <CardComponent {...item} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default WhyChooseUs;
