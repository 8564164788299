import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "../components/generic/Loader";
import Blog from "../pages/Blog";
import AboutUs from "../components/other_pages/AboutUs";
import Header from "../components/home/Header";
import Footer from "../components/home/Footer";
import Home from "../pages/Home";
import ScrollToTop from "../utils/ScrollToTop";
import PackageDetails from "../components/booking/PackageDetails";

const ServiceDetails = lazy(() =>
  import("../components/booking/ServiceDetails")
);
const ServiceBooking = lazy(() =>
  import("../components/booking/ServiceBooking")
);
const BookingStatus = lazy(() => import("../components/booking/BookingStatus"));
const BookingList = lazy(() => import("../components/booking/BookingList"));
const BookingDetails = lazy(() =>
  import("../components/booking/BookingDetails")
);
const BillList = lazy(() => import("../components/bill/BillList"));
const PaymentList = lazy(() => import("../components/payment/PaymentList"));
const PaymentStatus = lazy(() => import("../components/payment/PaymentStatus"));
const Address = lazy(() => import("../components/address/Address"));
const AddressList = lazy(() => import("../components/address/AddressList"));
const AddressCreate = lazy(() => import("../components/address/AddressCreate"));
const AddressEdit = lazy(() => import("../components/address/AddressEdit"));
const Subscription = lazy(() =>
  import("../components/subscription/Subscription")
);
const SubscriptionCreate = lazy(() =>
  import("../components/subscription/SubscriptionCreate")
);
const SubscriptionDetails = lazy(() =>
  import("../components/subscription/SubscriptionDetails")
);
const Profile = lazy(() => import("../components/profile/Profile"));
const Plans = lazy(() => import("../components/other_pages/plans"));
const BlogDetailPage = lazy(() =>
  import("../components/other_pages/BlogDetailPage")
);

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/aboutus" element={<AboutUs />} />
            <Route exact path="/blog/:id/:title" element={<BlogDetailPage />} />
            <Route exact path="/package" element={<PackageDetails />} />
            <Route exact path="/package/service" element={<ServiceDetails />} />
            <Route
              exact
              path="/package/service/booking"
              element={<ServiceBooking />}
            />
            <Route
              exact
              path="/package/service/booking/bookingStatus"
              element={<BookingStatus />}
            />
            <Route exact path="/bookingList" element={<BookingList />} />
            <Route exact path="/bookingDetails" element={<BookingDetails />} />
            <Route exact path="/billList" element={<BillList />} />
            <Route exact path="/paymentList" element={<PaymentList />} />
            <Route
              exact
              path="/billList/paymentStatus"
              element={<PaymentStatus />}
            />
            <Route exact path="/address" element={<Address />} />
            <Route
              exact
              path="/address/addressCreate"
              element={<AddressCreate />}
            />
            <Route
              exact
              path="/address/addressEdit"
              element={<AddressEdit />}
            />
            <Route exact path="/subscription" element={<Subscription />} />
            <Route
              exact
              path="/subscription/subscriptionCreate"
              element={<SubscriptionCreate />}
            />
            <Route
              exact
              path="/subscription/subscriptionDetails"
              element={<SubscriptionDetails />}
            />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/plans" element={<Plans />} />
            <Route exact path="*" element={<h1>Not Found</h1>} />
          </Routes>
          <Footer />
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}
