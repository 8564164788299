import React from "react";
import BlogHeader from "../components/home/BlogHeader";
import BlogCard from "../components/home/BlogCard";

const Blog = () => {
    return (
        <div>
            <BlogHeader />
            <BlogCard />
        </div>
    )
}
export default Blog;