import React from "react";
import insurance from "../../assets/media/insurance.svg";
import register from "../../assets/media/register.svg";
import refer from "../../assets/media/refer.svg";

export default function OtherOptions() {
  return (
    <section className="otherOptions-section">
      <div className="otherOptions-container">
        {/* <div className="oo-box oo-box-1">
          <p className="oo-title">Secure your worker's health and future</p>
          <p className="oo-text">
            We know that sometimes life happens, and your workers
            <br />
            might need some extra help when it comes time for them
            <br />
            to seek medical care.
          </p>
          <button className="insurance-btn">Help To Get Insurance</button>
        </div> 

        <div
          className="oo-box oo-box-2"
          style={{ backgroundImage: `url(${insurance})` }}
        ></div>*/}

        <div className="oo-box oo-box-3">
          <img src={register} className="oo-box-img" />
          <div>
            <p className="oo-title">Register yourself as a Maid</p>
            <p className="oo-text">
              We know that sometimes life happens, and your
              <br /> workers might need some extra help when it comes
              <br /> time for them to seek medical care.
            </p>
          </div>
          <div className="d-flex justify-content-between h-50">
            <a href="https://forms.gle/vCx4qc3AGmzLCj3HA">
              <button className="register-btn">Register Now!</button>
            </a>
            <div
              className="register-icon-wrapper"
              style={{ backgroundImage: `url(${register})` }}
            ></div>
          </div>
        </div>

        <div className="oo-box oo-box-4">
          <img src={refer} className="oo-box-img" />
          <div>
            <p className="oo-title">Refer a Maid and get reward</p>
            <p className="oo-text">
              We know that sometimes life happens, and your
              <br /> workers might need some extra help when it comes
              <br /> time for them to seek medical care.
            </p>
          </div>
          <div className="d-flex justify-content-between h-50">
            <a href="https://forms.gle/vCx4qc3AGmzLCj3HA">
              <button className="submit-btn">Submit Worker Details</button>
            </a>
            <div
              className="register-icon-wrapper"
              style={{ backgroundImage: `url(${refer})` }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
}
