import React from 'react';
import { Card, CardContent, Typography, CardMedia, Button, CardActions, Divider, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

function BlogCard() {
    const navigate = useNavigate();

    const handleClick = (id, title) => {
        console.log(id,title);
        navigate(`/blog/${id}/${title}`);
    }
    const theme = createTheme({
        palette: {
            primary: {
                main: '#e41211'
            }
        },
    });

    const blogData = [
        {
            id: 1,
            title: 'Challenges Millennials Encounter When Hiring Domestic Helpers.',
            secondaryText: '1. Shortage of skilled workers: The demand for domestic helpers has increased in Delhi, but there is a shortage of skilled workers,which makes it challenging to find a suitable candidate.',
            imageUrl: 'https://i.imgur.com/evykVZL.jpeg'
        },
        {
            id: 2,
            title: 'How to Find and Hire a Reliable and Trustworthy Domestic Helper?',
            secondaryText: 'Finding a reliable and trustworthy domestic helper can be a daunting task. You want someone who is not only professional and reliable but also trustworthy and experienced.',
            imageUrl: 'https://i.imgur.com/evykVZL.jpeg'
        },
        {
            id: 3,
            title: 'The Benefits of Hiring Domestic Helpers from a Professional Company',
            secondaryText: 'Hiring domestic helpers can be a great way to make your life easier and more manageable. However, finding a reliable and trustworthy domestic helper..',
            imageUrl: 'https://i.imgur.com/evykVZL.jpeg'
        },
        {
            id: 4,
            title: '5 Qualities to Look for When Hiring a Domestic Helper for Household Services',
            secondaryText: 'Hiring a domestic helper, such as a maid or housekeeper, can provide valuable support for busy households or those with specific needs. However, finding the right candidate',
            imageUrl: 'https://i.imgur.com/evykVZL.jpeg'
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <div>
                <Typography variant='h4' style={{ padding: '20px 0px 10px 30px' }}>
                    <span style={{ color: 'black' }}>Featured</span> <span style={{ color: '#e41211' }}>articles</span>
                </Typography>
                <div className='blogs-section'>
                    <div className='card-section'>
                        {blogData.map((blog) => (
                            <Card className="card" id={blog.id} sx={{height: 'auto'}}>
                                {/* {blog.imageUrl && <CardMedia
                                    sx={{ height: 140 }}
                                    image={blog.imageUrl}
                                    title="green iguana"
                                />} */}
                                <CardContent>
                                    <Typography gutterBottom variant="h5" fontSize={'1.2rem'} className='line_clamp'>
                                        {blog.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" className='line_clamp'>
                                        {blog.secondaryText}
                                    </Typography>
                                </CardContent>
                                <Divider variant="middle" />
                                <CardActions sx={{display:'flex', justifyContent:"flex-end"}}>
                                    <Button size="small" style={{
                                        textDecoration: 'underline',
                                        fontWeight: 'medium',
                                        textTransform: 'none',
                                        fontSize: '15px'
                                    }} onClick={() => handleClick(blog.id, blog.title)}>Read Article</Button>
                                </CardActions>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}
export default BlogCard;
