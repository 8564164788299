import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  CardMedia,
} from "@mui/material";
import aboutUsGoals from "../../assets/media/about-us-goal.png";

function Goal() {
  return (
    <Card
      sx={{
        display: "flex",
        boxShadow: "none",
        padding: { xs: "0rem", lg: "2rem, 2rem, 2rem, 0" },
      }}
    >
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <CardMedia
          component="img"
          image={aboutUsGoals}
          sx={{ width: { xs: 275, sm: 700 }, height: { xs: 325, sm: 700 } }}
          alt="Live from space album cover"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            flexBasis: "700px",
            flexGrow: "1",
            padding: { xs: "1rem", lg: "4rem" },
          }}
        >
          <CardHeader
            title="Our goal is to make sure all your needs are fulfilled"
            titleTypographyProps={{ variant: "h4", fontWeight: 700 }}
          />
          <CardContent>
            <Typography variant="body1" color="text.secondary" sx={{ fontSize: 20 }}>
            The Helpmate is an online platform for fulfilling all your domestic services. 
            We aim to provide the right maid or domestic helper for you through our platform. 
            The platform allows you to hire maids for cooking, cleaning - brooming/mopping, dish washing, laundry, baby care etc
            </Typography>
          </CardContent>
        </Box>
      </div>
    </Card>
  );
}
export default Goal;
