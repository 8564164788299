import React, { useState, useEffect } from "react";
import headerLogo from "../../assets/media/header-logo.svg";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Box,
  Drawer,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// A custom hook to get the window width
function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return width;
}

const useHash = () => {
  const [hash, setHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash);
    };
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return hash;
};

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

// The main app component
function App(props) {
  const location = useLocation();
  const [active, setActive] = useState("");
  const hash = useHash();
  useEffect(() => {
    console.log(hash);
    // Set active state based on location.pathname
    if (location.pathname === "/" && hash !== "#testimonial") {
      setActive("Home");
    } else if (location.pathname === "/aboutus") {
      setActive("About Us");
    } else if (location.pathname === "/blog") {
      setActive("Blogs");
    } else if (hash === "#testimonial") {
      setActive("Testimonials");
    } else if (hash === "#faq") {
      setActive("FAQs");
    } else {
      setActive("");
    }
  }, [hash]);
  const width = useWindowWidth(); // Get the current window width
  const [drawerOpen, setDrawerOpen] = React.useState(false); // State to control the drawer

  // A function to toggle the drawer
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // A function to render the app bar links
  const renderLinks = () => {
    const LinkButton = styled(Button)(({ theme }) => ({
      color: "inherit",
      fontSize: width < 600 ? "1.5rem" : "1.25rem",
      textTransform: "capitalize",
      padding: "6px 12px",
      // '&:hover': {
      //   backgroundColor: purple[700],
      // },
    }));
    // Define the paths for each link
    const homePath = "/#packageType";
    const aboutPath = "/aboutus";
    const blogPath = "/blog";

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: width < 600 ? "1.5rem" : ".5rem",
          flexDirection: width < 600 ? "column" : "row",
          fontFamily: "Arial",
        }}
      >
        {/* Use the NavLink component with activeStyle and exact props for each link */}
        <NavLink
          to="/"
          exact
          activeStyle={{ color: "#e41211" }}
          style={{
            color: active === "Home" ? "#e41211" : "black",
            textDecoration: "none",
          }}
          onClick={(e) => {
            e.preventDefault();
            //window.scrollTo(0, 0);
            window.location.replace("/#packageType");
            setActive("Home");
          }}
        >
          <LinkButton>Home</LinkButton>
        </NavLink>
        <NavLink
          onClick={() => {
            setActive("Blogs");
            //window.scrollTo(0, 0);
          }}
          to={blogPath}
          exact
          isActive={(match) => match && match.url === "/blog"}
          style={{
            color: active === "Blogs" ? "#e41211" : "black",
            textDecoration: "none",
          }}
        >
          <LinkButton>Blogs</LinkButton>
        </NavLink>
        <NavLink
          to={aboutPath}
          exact
          isActive={(match) => match && match.url === "/aboutus"}
          style={{
            color: active === "About Us" ? "#e41211" : "black",
            textDecoration: "none",
          }}
          onClick={() => {
            setActive("About Us");
            //window.scrollTo(0, 0);
          }}
        >
          <LinkButton>About Us</LinkButton>
        </NavLink>
        <span
          onClick={(e) => {
            e.preventDefault();
           // window.scrollTo(0, 0);
            window.location.replace("/#testimonial");
            setActive("Testimonials");
          }}
        >
          <HashLink
            smooth
            to="/#testimonial"
            style={{
              color: active === "Testimonials" ? "#e41211" : "black",
              textDecoration: "none",
            }}
          >
            <LinkButton>Testimonials</LinkButton>
          </HashLink>
        </span>
        <span
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
            window.location.replace("/#faq");
            setActive("FAQs");
          }}
        >
          <HashLink
            smooth
            to="/#faq"
            style={{
              color: active === "FAQs" ? "#e41211" : "black",
              textDecoration: "none",
            }}
          >
            <LinkButton>FAQs</LinkButton>
          </HashLink>
        </span>
      </Box>
    );
  };

  // A function to render the drawer content
  const renderDrawer = () => {
    return (
      <Box sx={{ alignItems: "flex-start" }} onClick={toggleDrawer}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#e41211",
            padding: "0px 15px 0px 20px",
            color: "white",
          }}
        >
          <Typography
            variant="h5"
            sx={{ my: 2, display: "flex", justifyContent: "center" }}
          >
            Menu
          </Typography>
          <IconButton onClick={toggleDrawer} sx={{ color: "white" }}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 1.5 }} />
        {renderLinks()}
        {/* Move the contact us button to the bottom of the drawer */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            padding: "2rem",
          }}
        >
          <Typography
            style={{
              textDecoration: "none",
              color: "#686868",
              fontWeight: "bold",
              fontSize: "0.9rem",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<EmailOutlinedIcon />}
              sx={{ width: "100%", bgcolor: "#e41211", height: "3.25rem" }}
              onClick={() => {
                if (window.innerWidth <= 768) {
                  window.location.href = "tel:9910779904";
                }
              }}
            >
              Contact Us
            </Button>
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          position="sticky"
          sx={{ backgroundColor: "#fff", color: "#000" }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            {/* If the window width is less than 600px, show the menu icon on the left and logo on the right */}
            {width < 600 ? (
              <>
                {/* Use flex-start to align the logo to the left */}
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <img src={headerLogo} alt="Header Logo" />
                </Box>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              // Otherwise, show the logo on the left and the links on the right
              <>
                <Box
                  sx={{
                    flexGrow: 1,
                    maxWidth: "45%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={headerLogo} alt="Header Logo" />
                </Box>

                {renderLinks()}
                {/* Move the contact us button to the extreme right end */}
                <Typography
                  style={{
                    textDecoration: "none",
                    color: "#686868",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  Call us at{" "}
                  <span style={{ color: "#e41211", fontSize: "1.2rem" }}>
                    9910779904
                  </span>
                </Typography>
              </>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Drawer
        anchor="right"
        transitionDuration={150}
        open={drawerOpen}
        onClose={toggleDrawer}
        PaperProps={{ sx: { width: "75%" } }}
      >
        {renderDrawer()}
      </Drawer>
    </>
  );
}

export default App;