import getEnvVars from "../environment.js";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPhoneNumber, signOut } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const {
  ENV_FIREBASE_API_KEY,
  ENV_FIREBASE_AUTH_DOMAIN,
  ENV_FIREBASE_PROJECT_ID,
  ENV_FIREBASE_STORAGE_BUCKET,
  ENV_FIREBASE_MESSAGING_SENDERID,
  ENV_FIREBASE_APP_ID,
  ENV_FIREBASE_MEASUREMENT_ID,
} = getEnvVars();

const firebaseConfig = {
  apiKey: ENV_FIREBASE_API_KEY,
  authDomain: ENV_FIREBASE_AUTH_DOMAIN,
  projectId: ENV_FIREBASE_PROJECT_ID,
  storageBucket: ENV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV_FIREBASE_MESSAGING_SENDERID,
  appId: ENV_FIREBASE_APP_ID,
  measurementId: ENV_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

const auth = getAuth(app);

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
  }
};

const setUser = (user) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    localStorage.removeItem("user");
  }
};

const logInWithPhoneNumber = async (phoneNumber, appVerifier) => {
  let phoneWithCode = '+91' + phoneNumber;
  try {
    await signInWithPhoneNumber(auth, phoneWithCode, appVerifier)
      .then(function (confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("confirmationResult - success");
        console.log("confirmationResult", confirmationResult);
      })
      .catch(function (error) {
        // Error; SMS not sent
        console.error("Error during signInWithPhoneNumber", error);
        window.signingIn = false;
      });
  } catch (err) {
    console.error(err);
  }
};

const verifyOTP = async (code) => {
  try {
    console.log("Verifying OTP code");
    window.verifyingCode = true;
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(code)
      .then(function (result) {
        // User signed in successfully.
        console.log("Code verified , user signed in successfully");
        var user = result.user;
        window.confirmationResult = null;
        // console.log("user - ", user);
        setUser(user);
        localStorage.setItem('userPhone', user?.phoneNumber);
        // window.location.reload();
      })
      .catch(function (error) {
        // User couldn't sign in (bad verification code?)
        console.error("Error while checking the verification code", error);
      });
  } catch (err) {
    console.error(err);
  }
};

const signOutFunc = () => {
  console.log('signout function called')
  signOut(auth).then((res) => {
    // Sign-out successful.
    console.log('signout success: ', res)
  }).catch((error) => {
    // An error happened.
    console.log('signout error: ', error)
  });
}


export { auth, logInWithPhoneNumber, verifyOTP, signOutFunc };
