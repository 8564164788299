import React, { useState, useEffect } from "react";
import { Box, ImageList, ImageListItem } from "@mui/material";
import about_us_banner from "../../assets/media/about_us_banner.svg";

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return width;
}

export default function AboutsUsBanner() {
  const isMobile = useWindowWidth();
  return (
    <Box sx={{ width: "100vw", display: isMobile < 600 ? "none" : "block" }}>
      <ImageList variant="masonry" cols={1} gap={0}>
        <ImageListItem>
          <img src={about_us_banner} alt="About us banner" />
        </ImageListItem>
      </ImageList>
    </Box>
  );
}
