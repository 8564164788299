import React from 'react';
import Testimonials from '../home/Testimonials';
import AboutsUsBanner from '../about_us/Banner';
import MeetTheTeam from '../about_us/MeetTheTeam';
import Vision from '../about_us/Vission';
import Goal from '../about_us/Goal';
import FeaturedSection from '../about_us/FeaturedOn';

export default function AboutsUs() {
    return (
        <div>
            <AboutsUsBanner />
            <Vision />
            <Goal />
            <MeetTheTeam />
            <FeaturedSection />
            <Testimonials />
        </div>
    );
}