import AppRoutes from "./routes/AppRoutes";
import GlobalInfo from "./context/GlobalInfo";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e41211",
    },
  },
});

export default function App() {
  return (
    <GlobalInfo>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </GlobalInfo>
  );
}
