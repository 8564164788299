import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Constants from "../constants/Constants";
import { back, whatsapp } from "../constants/ImagePaths";
import {Snackbar,Box,Modal} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { GlobalInfo } from '../context/GlobalInfo';
import Login from '../components/auth/Login';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ServiceBaseLayout(props) {
  let navigate = useNavigate();
  let packagePriceEstimated = "₹156 - ₹196";
  const { snack,modalLogin } = useContext(GlobalInfo);
  const [snackbar, setSnackbar] = snack;
  const [loginModal, setLoginModal] = modalLogin;


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20em",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    py: 4,
    borderRadius: "10px",
    overflow: "hidden",
    outline: 0,
    border: "0px",
  };


  return (
    <div className="packageDetailsContainer">

      <Modal
        open={loginModal}
        onClose={() => setLoginModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Login />
        </Box>
      </Modal>

      {/* snackbar */}
      <Snackbar open={snackbar.isOpen} autoHideDuration={3000} onClose={() => setSnackbar(prev => ({ ...prev, isOpen: false }))}>
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, isOpen: false }))} severity={snackbar.type} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>


      {/* Header */}
      <div className="packageTypeHeader">
        {props.title === Constants.TITLE_BOOKING_LIST || props.title === Constants.TITLE_PAYMENT_LIST ? (
          <div className="packageDetailsHeader">
            <div className="pt-title-wrapper">
              <img
                src={back}
                className="backBtn"
                onClick={() => navigate("/")}
              />
              <p className="pt-title">{props.title}</p>
            </div>
            <div className="helpWrapper" style={{ cursor: 'pointer' }} onClick={() => window.open("https://wa.me/9910779904")}>
              <img src={whatsapp} />
              <p className="pt-text">Help</p>
            </div>
          </div>
        ) : (
          <div className="packageDetailsHeader">
            <div className="pt-title-wrapper">
              <img
                src={back}
                className="backBtn"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(-1)}
              />
              <p className="pt-title">{props.title}</p>
            </div>
            <div className="helpWrapper" style={{ cursor: 'pointer' }} onClick={() => window.open("https://wa.me/9910779904")}>
              <img src={whatsapp} />
              <p className="pt-text">Help</p>
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="content">{props.children}</div>

        {/* Pricing and Select Slot Button -- Render only for Service Details Page */}
        {/* {props.title === Constants.TITLE_SERVICE_DETAILS || Constants.TITLE_BOOKING_LIST ? (
          null
        ) :
          <div
            className="d-flex justify-content-between align-items-center px-2 py-2 bottom-btn-bar"
            onClick={() => navigate("/package/service/booking")}
          >
            <div>
              <p className="mb-0 book-price-text">Estimated amount</p>
              <p className="mb-0 book-price">
                <strong>{packagePriceEstimated}</strong>/day
              </p>
            </div>
            <button className="book-btn">Select Slot</button>
          </div>
        } */}
      </div>
    </div>
  );
}
