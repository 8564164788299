const faq = [
  {
    question: "How can I hire a housemaid/babysitter/cook from The Helpmate?",
    answer:
      `Create a booking by clicking on the button "Book your services" now or call us directly at 9910779904.`,
  },
  {
    question: "What if I am not satified with the maid service?",
    answer:
      "Our startup prioritizes customer satisfaction and provides the right helper for your needs. While our helpers are extensively trained, we offer replacements if necessary. Our dedicated relationship managers are available to assist you, ensuring quality, reliability, and peace of mind.",
  },
  {
    question: "What does your domestic services cost?",
    answer:
      "We offer competitive market pricing that reflects our commitment to fair and transparent pricing. Our goal is to provide high-quality services at affordable prices that deliver exceptional value to our clients.",
  },
  {
    question: "Are your maids reliable?",
    answer:
      "All the helpers registered with The Helpmate are background verfied and police verfied. There IDs are collected and are verfied.",
  },
];

export default faq;